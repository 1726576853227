<template>
    <li class="p-4 rounded-lg shadow-sm bg-gray-50 dark:bg-slate-200">
        <div class="flex items-start justify-between">
            <div class="flex-1">
                <p class="text-gray-700 font-semibold" v-if="reply.sendUser && reply.sendUser.name">
                    {{ reply.sendUser.name }}
                </p>
                <p class="text-gray-700" v-else>未知用户</p>
                <p class="text-gray-600 mb-1 text-sm">{{ formatTime(reply.createTime) }}</p>
                <BitfreeMdPreview class="text-left text-gray-800"
                    :md-text="replaceEmojiWithImages(reply.replyContent)" />
            </div>
            <div class="flex items-center">
                <button @click="toggleReplyInput" class="ml-4 text-blue-500">回复</button>
                <button v-if="isCurrentUser(reply.sendUser)" @click="deleteReply(reply.id)" class="ml-4 text-red-500">
                    删除
                </button>
            </div>
        </div>
        <div v-if="showReplyInput" class="mt-2">
            <textarea ref="replyTextarea" v-model="replyContent"
                class="w-full h-24 p-2 border border-gray-300 rounded-lg resize-none"
                placeholder="输入评论，支持 markdown 格式"></textarea>
            <button @click="addReply(reply.id)"
                class="self-end bg-blue-500 text-white px-4 py-2 mt-2 rounded-lg hover:bg-blue-600">
                回复
            </button>
        </div>
        <div v-if="reply.subReplyList && reply.subReplyList.length" class="mt-2 pl-4">
            <button @click="toggleSubReplies" class="text-blue-500">
                {{ showSubReplies ? '隐藏回复' : '显示回复' }} ({{ reply.subReplyList.length }})
            </button>
            <ul v-if="showSubReplies" class="space-y-2">
                <ReplyItem v-for="subReply in reply.subReplyList" :key="subReply.id" :reply="subReply"
                    @reply-added="handleReplyAdded" />
            </ul>
        </div>
    </li>
</template>

<script>
import { formatTime, replaceEmojiWithImages } from '@/common/common';
import BitfreeMdPreview from './BitfreeMdPreview.vue';
import { deletePostReply, replyPost } from '@/openapi/post';
import { md5 } from 'js-md5';

export default {
    name: 'ReplyItem',
    props: {
        reply: Object
    },
    data() {
        return {
            showReplyInput: false,
            showSubReplies: false,
            replyContent: ''
        };
    },
    components: {
        BitfreeMdPreview
    },
    methods: {
        formatTime,
        addReply(replyId) {
            replyPost(
                {
                    postId: this.$route.query.id,
                    replyContent: this.replyContent,
                    replyId: replyId
                },
                JSON.parse(localStorage.getItem("user"))?.id + '-' + md5(this.replyContent)
            ).then(() => {
                this.replyContent = "";
                this.showReplyInput = false;
                this.$emit('reply-added');
            }).catch((error) => {
                console.error("Error adding reply:", error);
            });
        },
        deleteReply(replyId) {
            const isConfirmed = confirm('确定删除？');
            if (isConfirmed) {
                deletePostReply({ id: replyId })
                    .then(() => {
                        this.$emit('reply-added');
                    })
                    .catch((error) => {
                        console.error("Error deleting reply:", error);
                    });
            }
        },
        isCurrentUser(sendUser) {
            const currentUserId = JSON.parse(localStorage.getItem("user"))?.id || 0;
            return currentUserId === sendUser?.id;
        },
        handleReplyAdded() {
            this.$emit('reply-added');
        },
        toggleReplyInput() {
            if (!this.showReplyInput && this.reply.sendUser && this.reply.sendUser.name) {
                this.replyContent = `@${this.reply.sendUser.name} `;
            }
            this.showReplyInput = !this.showReplyInput;

            this.$nextTick(() => {
                if (this.showReplyInput) {
                    this.$refs.replyTextarea.focus();
                }
            });
        },
        toggleSubReplies() {
            this.showSubReplies = !this.showSubReplies;
        },
        replaceEmojiWithImages,
    }
};
</script>

<style scoped>
.markdown-body {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    font-size: 16px;
    text-align: left !important;
}
</style>
